






























































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'
import Editor from '@/components/Editor/index.vue'
import { getDicts, getDictName } from '@/utils/dict'
import { DatePicker, Autocomplete, Checkbox } from 'element-ui'
Vue.use(DatePicker)
Vue.use(Autocomplete)
Vue.use(Checkbox)

@Component({
  components: {
    Editor
  }
})
export default class extends Vue {
  // 消息类型字典
  noticeTypeDict: any = []
  loading = false
  info = {
    type: null,
    isShow: false,
    // 标题
    title: '',
    // 时间
    time: '',
    // 内容
    content: ''
  }
  // 模糊查询房屋的信息
  keySearchWord = ''
  // 选择的房屋
  selectedItems: any = []

  // 没有上传家属的房屋
  unOwnerNameSelectItems: any = []

  isHover = false
  isAllChecked = false
  allSourceData = [] as any
  tempSourceData2 = [] as any
  preHouse: any = [] //房屋列表已选择的房屋数组
  // 时间限制
  pickerOptions: any = {
    disabledDate(time: any) {
      return time.getTime() < Date.now() - 1 * 24 * 3600 * 1000 //选当前时间之后的时间包含今天
    }
  }

  async beforeCreate() {
    // 获取相关字典
    // 报修状态
    this.noticeTypeDict = await getDicts('NOTICE_TYPE')
  }
  // 获取字典中值对应的名称
  filterDictName(dict: any, val: any) {
    return dict.length === 0 ? '' : getDictName(dict, val)
  }
  mounted() {
    if (this.$route.query.houseIds) {
      this.preHouse = JSON.parse(this.$route.query.houseIds as any) //房屋列表已选择的房屋id数组
    }
    // 初始获取所有的房屋
    this.getAllPushHouseLists()
  }
  getAllPushHouseLists() {
    this.unOwnerNameSelectItems = []
    this.$api.property.getPushHouseLists().then((res: any) => {
      if (res.data.success) {
        res.data.data.map((v: any) => {
          v.isChecked = false
        })
        this.allSourceData = res.data.data
        this.tempSourceData2 = JSON.parse(JSON.stringify(res.data.data))
        let isIn = false
        this.preHouse.forEach((pre: any) => {
          isIn = false
          this.tempSourceData2.forEach((item: any) => {
            if (item.id === pre.id) {
              item.isChecked = true
              isIn = true
              this.selectedItems.push(item)
            }
          })
          if (!isIn) {
            this.unOwnerNameSelectItems.push(pre)
          }
        })
        // this.tempSourceData2.map((item: any) => {
        //   this.preHouse.forEach((pre: any) => {
        //     if (item.id === pre.id) {
        //       item.isChecked = true
        //       this.selectedItems.push(item)
        //     } else if(item.ownerName !== '' ){
        //       this.unOwnerNameSelectItems.push(pre)
        //     }
        //   })
        // })
      }
    })
  }
  getPushHouseLists(params?: any) {
    this.$api.property.getPushHouseLists(params).then((res: any) => {
      if (res.data.success) {
        res.data.data.map((v: any) => {
          v.isChecked = false
          if (this.selectedItems.length > 0) {
            this.selectedItems.forEach((item: any) => {
              if (item.id === v.id) {
                v.isChecked = true
              }
            })
          }
        })
        this.tempSourceData2 = res.data.data
        this.chargeIsALLChecked()
        if (this.tempSourceData2.length === 0) {
          this.isAllChecked = false
        }
      }
    })
  }
  // 删除选中的tag
  deleteSelectTag(item: any) {
    this.tempSourceData2.map((v: any) => {
      if (item.id === v.id) {
        v.isChecked = false
      }
    })
    this.allSourceData.map((v: any) => {
      if (item.id === v.id) {
        v.isChecked = false
      }
    })
    this.getSelectedHouse()
  }
  // 获取选中的房屋
  getSelectedHouse() {
    this.tempSourceData2.forEach((temp: any) => {
      this.allSourceData.map((v: any) => {
        if (temp.id === v.id) {
          v.isChecked = temp.isChecked
        }
      })
    })
    this.selectedItems = []
    this.selectedItems = this.allSourceData.filter(
      (item: any) => item.isChecked
    )
  }
  // 清除搜索内容
  clearInput() {
    this.keySearchWord = ''
    // this.searchChange()
    this.tempSourceData2 = JSON.parse(JSON.stringify(this.allSourceData))
    this.chargeIsALLChecked()
  }

  // 全员推送
  allCheckedChange(val: boolean) {
    this.tempSourceData2.map((v: any) => {
      return (v.isChecked = val)
    })
    this.getSelectedHouse()
  }

  // 选中单个
  singleCheckedChange() {
    this.chargeIsALLChecked()
  }

  // 判断所有数据中是否都全选中
  chargeIsALLChecked() {
    const isAllChoosed =
      this.tempSourceData2.findIndex((v: any) => !v.isChecked) === -1
        ? true
        : false
    this.isAllChecked = isAllChoosed
    this.getSelectedHouse()
  }

  // 更改搜索内容
  searchChange() {
    this.getPushHouseLists({ keySearchWord: this.keySearchWord })
  }

  // 获取富文本编辑信息
  getTxt(data: any) {
    this.info.content = data
  }

  // 推送消息
  submit() {
    const houseIdList: any = []
    const residentIdList: any = []
    this.selectedItems.map((s: any) => {
      houseIdList.push(s.id)
      residentIdList.push(s.ownerId)
    })
    this.unOwnerNameSelectItems.forEach((u: any) => {
      houseIdList.push(u.id)
    })
    if (!this.info.type) {
      return this.$message.error('请填写消息类型')
    }
    if (!this.info.title || this.info.title === '') {
      return this.$message.error('请填写消息标题')
    }
    if (!this.info.content || this.info.content === '') {
      return this.$message.error('请填写内容')
    }
    if (this.selectedItems.length === 0) {
      return this.$message.error('请选择推送房屋')
    }
    if (this.info.isShow && (!this.info.time || this.info.time === '')) {
      return this.$message.error('请填写消息展示截止日期')
    }
    const u = sessionStorage.getItem('user')
    const user = u && JSON.parse(u)
    const p = {
      // 此处需要用户信息
      author: user.username,
      content: this.info.content,
      expireDate: this.info.time,
      homeShow: this.info.isShow ? 1 : 0,
      noticeType: this.info.type,
      title: this.info.title,
      houseIdList: houseIdList,
      residentIdList: residentIdList
    }
    this.loading = true
    this.$api.property
      .publiceNotice(p)
      .then((res: any) => {
        this.loading = false
        if (res.data.success) {
          this.$message.success(res.data.msg)
          this.$router.push('/property/news-history')
        } else {
          this.$message.error(res.data.msg)
        }
      })
      .catch(() => {
        this.loading = false
      })
  }

  toHistory() {
    this.$router.push('/property/news-history')
  }
}
